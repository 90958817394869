<template>
  <div>
    <top-bar :title="'办事指南'" :left="true"></top-bar>
    <van-tabs>
      <van-tab title="办事大厅">
        <div class="list-info">
          <div class="search">
            <input v-model="searchValue" type="search" value="搜索" placeholder="搜索" class="search-content" @keyup.enter="onSearch">
            <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
            <div class="search-btn" @click="onSearch">搜索</div>
          </div>
          <div class="cont">
            <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
                <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
                <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
                  <div class="content">
                    <div class="content-text">
                      <div class="top-text">{{item.title}}</div>
                      <div class="right-text">{{item.orgName}}</div>
                      <div class="bottom-text">时间: {{item.createTime}}</div>
                    </div>
                  </div>
                </div>
              </van-list>
              <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
            <!-- </van-pull-refresh> -->
          </div>
        </div>
      </van-tab>
      <van-tab title="咨询列表">
        <div class="list-info">
          <div class="search">
            <input v-model="proSearchValue" type="search" value="搜索" placeholder="搜索" class="search-content" @keyup.enter="onSearch">
            <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
            <div class="search-btn" @click="onSearch">搜索</div>
          </div>
          <div class="cont">
            <van-list v-model="proLoading" :finished="proFinished" finished-text="没有更多了" @load="getProList" v-if="proList.length > 0" offset="10">
                <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{proTotalCount}}</span>&nbsp;条数据</div>
                <div class="list-item" v-for="item in proList" :key="item.id" @click="goConsultInfo(item.id)">
                  <div class="pro-content">
                    <div class="top">
                      <div class="top-left">{{item.questionTitle}}</div>
                      <div class="top-right" :style="item.hasAnswer ? 'color:#387FF5' : 'color: #f00;'">{{item.hasAnswer ? '已回复' : '待回复'}}</div>
                    </div>
                    <div class="mid">
                      <div class="mid-left">{{item.questionTime}}</div>
                      <img class="mid-right" :src="require('@/assets/img/goto.png')" alt="">
                    </div>
                    <div class="down">{{item.questionContent}}</div>
                  </div>
                </div>
              </van-list>
              <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { Toast } from 'vant'

import { mapMutations } from 'vuex'
export default {
    components :{
        topBar
    },
     data() {
        return {
          pullLoading: false,
          totalCount: 0,
          searchValue: '',
          loading: false,
          finished: false,
          limit: 10,
          page: 0,
          dataList: [],
          proSearchValue: '',
          proTotalCount: 0,
          proLoading: false,
          proFinished: false,
          proLimit: 10,
          proPage: 0,
          proList: []
        };
     },
    methods: {
         ...mapMutations,
        getInfo (id) {
            this.$store.commit('setGuideId', id)
            this.$router.push('/guide-info')
        },
        getDataList () {
            this.page++
            this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
            this.$http({
                url: this.$http.adornUrl('/wxapp/guide/list'),
                method: 'post',
                params: this.$http.adornParams({
                    page: this.page,
                    limit: this.limit,
                    title: this.searchValue,  //搜索条件
                    appOrgId: this.$orgId,
                    orgId: this.$orgId
                })
                }).then(({data})=> {
                    if (data.code == 0) {
                      this.pullLoading = false
                        if (this.dataList.length == data.page.totalCount) {
                           this.finished = true
                       }
                       this.dataList = this.dataList.concat(data.page.list)
                       this.totalCount = data.page.totalCount
                        // 加载状态结束
                       this.loading = false;
                    }
             this.$toast.clear()
            },err=> {this.$toast.clear()})
        // 数据全部加载完成
        },
        onPullDownRefresh(e) {
          this.finished=false
          this.dataList = []
          this.page = 0;
          this.getDataList()
        },
        onSearch(val) {
            this.page = 0
            this.dataList = []
            this.getDataList()
        },
        onCancel() {
            this.page = 0
            this.dataList = []
            this.searchValue = ''
            this.getDataList()
        },
        getProList () {
            this.proPage++
            this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
            this.$http({
                url: this.$http.adornUrl('/wxapp/commonApp/guide/faqs/list'),
                method: 'post',
                params: this.$http.adornParams({
                    page: this.proPage,
                    limit: this.proLimit,
                    title: this.proSearchValue,  //搜索条件
                    orgId: this.$orgId
                })
                }).then(({data})=> {
                    if (data.code == 0) {
                      // this.pullLoading = false
                        if (this.proList.length == data.page.totalCount) {
                           this.proFinished = true
                       }
                       this.proList = this.proList.concat(data.page.list)
                       this.proTotalCount = data.page.totalCount
                        // 加载状态结束
                       this.proLoading = false;
                    }
             this.$toast.clear()
            },err=> {this.$toast.clear()})
        // 数据全部加载完成
        },
        goConsultInfo(id) {
          this.$router.push({path: '/consultInfo',query: {id}})
        }
    },
    created () {
        this.$toast.loading({
            duration: 0,
            message: '加载中...',
            forbidClick: true,
        });
        this.getDataList()
        this.getProList()
    }
}
</script>

<style lang="scss" scoped>
.search {
  padding-bottom: 20px !important;
}
.pro-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    // font-size: 30px;
    .top-left {
      font-size: 30px;
    }
    .top-right {
      font-size: 28px;
    }
  }
  .mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    .mid-right {
      width: 12px;
      height: 20px;
    }
  }
  .down {
    font-size: 30px;
    margin: 20px 0;
    color: #999999;
  }
}
</style>
